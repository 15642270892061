<div class="cover">
    <div class="close"><a on:click|preventDefault="{close}" href="/" title="close">&times;</a></div>
    <div id="loginModal" on:keydown="{event=>keydown(event)}">
        <img class="logo mb-3" title="Seacoast Vitamins" alt="Login or Register" src="/vendor/seacoast/img/seacoast-gull.svg">
        {#if msg}<div class="alert alert-dark">{msg}</div>{/if}
        <div id="login-forms form-group">
            {#if state==='choose'}
            <div>
                <input type='text' name='email' bind:value={email} placeholder='Your email address' class="form-control form-control-lg ">
                <button on:click="{() => doLogin()}" class="btn btn-secondary btn-login mb-2 {inProgress ? 'hidden' : ''}">Continue with email</button>
                <button on:click="{doFacebookLogin}" class="btn btn-primary facebook mt-2 mt-5">or login with Facebook</button>

            </div>
            {:else if state==='login'}
            <div>
                <input type="text" name="email" bind:value={email} placeholder="Your email" class="form-control form-control-lg">
                <input type="password" name="password" bind:value={password} placeholder="Your password" class="form-control form-control-lg">
                <button class="btn btn-primary {inProgress ? 'hidden' : ''}" on:click={doLogin}>Submit</button>
                <p class="mt-3"><a href="/" on:click|preventDefault="{()=>state='forgot'}">Forgot your password?</a></p>
                <p class="mt-3"><a href="/" on:click|preventDefault="{()=>state='choose'}">Go back</a></p>
            </div>
            {:else if state==='register'}
            <div>
                <input type="text" name="email" bind:value={email} placeholder="Your email address" class="form-control form-control-lg">
                <input type="password" name="password" bind:value={password} placeholder="Create a password"
                       class="form-control form-control-lg">
                <input type="password" name="passwordconf" bind:value={passwordconf} placeholder="Confirm password"
                       class="form-control form-control-lg">
                <p>
                    <label for="marketing"><input type="checkbox" id="marketing" name="marketing" bind:checked={marketing}>&nbsp;&nbsp;Send me offers and alerts for products that are relevant to me.</label>
                </p>
                <button on:click={doRegistration} class="btn btn-primary {inProgress ? 'hidden' : ''}">Register</button>
                <p class="mt-3">By registering, you agree to the <a class="nowrap" target="_blank" href="/terms.php">Terms of Service</a></p>
                <p class="mt-3"><a href="/" on:click|preventDefault="{()=>state='choose'}">Go back</a></p>
            </div>
            {:else if state==='forgot'}
            <div>
                <p>Enter your email. If it exists in our database, we'll reset it and send you a new one.</p>
                <input type="text" name="email" bind:value={email} placeholder="Email address" class="form-control form-control-lg">
                <button on:click={doReset} class="btn btn-primary {inProgress ? 'hidden' : ''}">Reset password</button>
                <p class="mt-3"><a href="/" on:click|preventDefault="{()=>state='choose'}">Go back</a></p>
            </div>
            {/if}
        </div>
        {#if inProgress}
        <div class="waiting"><b>{waiting}</b></div>
        {/if}
    </div>
</div>

<script>
    export let name;
    let email='', password='', passwordconf='', msg='',
        state='choose', marketing=true, inProgress = false,
        waiting = 'Please Wait', waitingTimer = null, dots='',
        stateMap = {'forgot': doReset, 'register': doRegistration, 'login': doLogin}

    $: setWaiting(inProgress)



    import utils from '../../common/utils'

    function setWaiting(doWait) {
        if (doWait) {
            waitingTimer = window.setInterval( function() {
                if ( dots.length > 3 )
                    dots ="";
                else
                    dots += ".";

                waiting="Please Wait" + dots
            }, 100)

        } else {
            clearInterval(waitingTimer)
        }
    }

    function doFacebookLogin() {

        if (typeof FB==="object") {
            FB.login(getFBStatus, {scope: 'email'})
        }

        window.fbAsyncInit = function() {
            FB.init({
                appId      : window.fbAppId,
                cookie     : true,
                xfbml      : true,
                version    : 'v3.3'
            });

            FB.login(getFBStatus, {scope: 'email'})


        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }

    function getFBStatus() {
        FB.getLoginStatus(function(response) {
            if(response.status==='connected') {
                inProgress = true
                utils.postData('/login.php', {fbtoken: response.authResponse.accessToken},
                    data => {
                        if (data.success) {
                            window.location=window.redirectTo
                        } else {
                            msg = data.msg;
                            inProgress = false
                            state='register'
                        }
                    },
                    error => {
                        msg=error
                        inProgress = false
                        state='register'
                    })
            } else {
                msg = 'Could not get access through Facebook. Try again'
                inProgress = false
            }
        });
    }

    function doLogin() {
        state='login'
        //if(validate({email, password})) {
            inProgress = true
            utils.postData('/login.php?action=process', {email, password},
                data => {
                    inProgress = false
                    if(data.success) {
                        window.location=window.redirectTo
                    } else if(data.emailvalid && !password) {
                        state = 'login'   
                    } else if(!data.emailvalid && !password) {
                        state = 'register'
                    } else {
                        msg=data.msg
                    }
                },
                error => {
                    msg=error
                    inProgress = false
                }
            )
        //}
    }

    function close() {
        window.history.back()
    }

    function doReset() {
        if(validate({email})) {
            inProgress = true
            utils.postData('/password_forgotten.php?action=process', {email, password},
                data => {
                    if(data.success) {
                        msg='If the email exists in our database, a new password will be sent.'
                        state='login'
                    } else {
                        msg=data.msg
                    }
                    inProgress = false
                },
                error => {
                    msg=error
                    inProgress = false
                }
            )
        }
    }

    function doRegistration() {
        msg = '';
        if(validate({email, password, passwordconf})) {
            inProgress = true
            utils.postData('/create_account.php?action=process', {email, password, marketing},
                data => {
                    if(data.success) {
                        window.location=window.redirectTo
                    } else {
                        msg=data.msg
                        inProgress = false
                    }
                },
                error => {
                    msg = error
                    inProgress = true
                }
            )
        }

    }


    function validate(what={email}) {
        if(what.email!==undefined) {
            if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(what.email)) {
                msg='Email address is invalid.'
                return false
            }
        }
        if(what.password!==undefined) {
            if(what.password.length < 5) {
                msg='Passwords must be at least 5 characters.'
                return false
            }
        }
        if(what.passwordconf!==undefined) {
            if(what.password!==what.passwordconf) {
                msg='The passwords do not match. Re-enter them.'
                return false
            }
        }

        return true;
    }

    function keydown(event) {
        if (event.key==='Enter') {
            if(stateMap[state]) {
                stateMap[state]()
            }

        } else {
            return true
        }
    }




</script>

<style>
    .waiting {
        width:10em;
        text-align:left;
        margin-left:auto;
        margin-right:auto;
    }
    .cover {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        min-height:100vh;
        background-color: #d3d3d3;

    }
    .close {
        font-size: 5vh;
        text-align: right;
        margin: 5px;
    }

    #loginModal {
        width: 100%;
        max-width: 400px;
        margin: auto;
        text-align: center;
        border-radius: 0.333em;
        border: solid #6c757d 1px ;
        background-color: #ffffff;
        padding:1.333em;
        padding-top:2em;
        padding-bottom: 2em;
    }

    .facebook {
        background-color: #0053b3;
        color: #ffffff;
        width: 100%;
    }

    .logo {
        width: 100%;
        max-width: 150px;
    }

    .btn {
        width: 100%;
    }

    .btn-login {
        background-color: #40832e;
    }

    .form-control {
        margin: 0.666em 0 0.666em;
        width: 100%;
    }


</style>



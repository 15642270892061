import App from './App.svelte';


window.setupAuth = function (target = document.body) {
	const app = new App({
		target: target,
		props: {
			name: 'world'
		}
	});


}

export default app;